import React from 'react';
import Layout from '../layouts/layout';
import { Link } from 'gatsby';

export default function Turkey(props) {
	return (
		<Layout title="Thanksgiving Turkey Dinner FAQ">
			<h1>Thanksgiving Turkey Dinner FAQ</h1>
			<p>
				Do you have questions about your turkey dinner? Look no further.
				If we can't answer your question here, feel free to give us a
				call at <a href="tel:+17602957993">(760) 295-7993</a>
			</p>
			<p>
				<strong>What happens after I place my order?</strong>
				<br />
				Here's what to expect:
				<ol>
					<li>
						Select from the Thanksgiving packages below to order
					</li>
					<li>We'll send you a confirmation of your order</li>
					<li>
						On Thanksgiving week, we'll have your Turkey dinner
						ready for pickup
					</li>
					<li>
						On Thanksgiving day, you just have to warm it up in the
						oven to eat! (We'll provide detailed instructions, so
						you'll know just what to do.)
					</li>
				</ol>
			</p>
			<p>
				<strong>
					Ready to order?{' '}
					<Link to="/turkey">Click here to order a turkey</Link>
				</strong>
			</p>
		</Layout>
	);
}
